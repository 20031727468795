import React from 'react';
import cx from 'classnames';
import { Whoosher } from '../Loaders';
import { ImageWithFallback } from '../ImageWithFallback';
import FallbackImage from '../../icons/iconic/svg/buildings.svg';
import * as styles from './AccountLogo.css';

export type Props = {
  accountName?: string | null;
  accountDomain?: string | null;
  size?: 'normal' | 'large';
  loading?: boolean;
};

export const AccountLogo = ({ accountDomain, accountName, size, loading }: Props) => {
  if (loading) {
    const whoosherSize = size === 'large' ? styles.largeSize : styles.normalSize;
    return <Whoosher height={whoosherSize} width={whoosherSize} />;
  }
  if (!accountDomain) {
    return null;
  }
  const clearbitSize = size === 'large' ? '120' : '40';
  return (
    <ImageWithFallback
      className={cx(styles.logo, { [styles.logoLarge]: size === 'large' })}
      src={`//logo.clearbit.com/${accountDomain}?size=${clearbitSize}`}
      alt={`${accountName ?? accountDomain} company logo`}
      width="24px"
      height="24px"
      fallbackSrc={FallbackImage}
    />
  );
};
