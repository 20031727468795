import React from 'react';

type ImageWithFallbackProps = Omit<React.HTMLProps<HTMLImageElement>, 'crossOrigin'> & { fallbackSrc: string };

export function ImageWithFallback({ fallbackSrc, ...props }: ImageWithFallbackProps) {
  return (
    <img
      {...props}
      alt={props.alt || ''}
      onError={(ev) => {
        // eslint-disable-next-line no-param-reassign
        ev.currentTarget.src = fallbackSrc;
        // eslint-disable-next-line no-param-reassign
        ev.currentTarget.onerror = null;
      }}
    />
  );
}
