import React, { ComponentProps } from 'react';
import * as PrimitiveTabs from '@radix-ui/react-tabs';
import * as styles from './Tabs.css';
import { Button as ButtonComponent } from '../Button';
import cx from 'classnames';
import { TabListVariants, TabVariants } from './Tabs.css';

export type RootProps = PrimitiveTabs.TabsProps & {
  fullHeight?: boolean;
};
export type ListProps = PrimitiveTabs.TabsListProps & {
  borderDisabled?: TabListVariants['borderDisabled'];
  alignment?: 'start' | 'center' | 'end';
  label?: string;
};
export type TabProps = PrimitiveTabs.TabsTriggerProps & {
  variant?: TabVariants['variant'];
};
export type ContentProps = PrimitiveTabs.TabsContentProps;
export type ButtonProps = ComponentProps<typeof ButtonComponent>;

type Orientation = RootProps['orientation'];
const TabsOrientationContext = React.createContext<Orientation>(undefined);

export const Root = ({ children, orientation = 'horizontal', fullHeight = false, ...props }: RootProps) => {
  return (
    <PrimitiveTabs.Root className={styles.root({ orientation, fullHeight })} orientation={orientation} {...props}>
      <TabsOrientationContext.Provider value={orientation}>{children}</TabsOrientationContext.Provider>
    </PrimitiveTabs.Root>
  );
};

export const List = ({ children, label, alignment, borderDisabled = false, ...props }: ListProps) => {
  const orientation = React.useContext(TabsOrientationContext);
  return (
    <PrimitiveTabs.List
      className={styles.list({ orientation, borderDisabled })}
      style={{ justifyContent: alignment }}
      aria-label={label}
      {...props}
    >
      {children}
    </PrimitiveTabs.List>
  );
};

export const Tab = ({ children, variant, ...props }: TabProps) => {
  const orientation = React.useContext(TabsOrientationContext);
  return (
    <PrimitiveTabs.TabsTrigger className={styles.tab({ orientation, variant })} {...props}>
      {children}
    </PrimitiveTabs.TabsTrigger>
  );
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }: ButtonProps, forwardedRef) => {
    const orientation = React.useContext(TabsOrientationContext);
    return (
      <ButtonComponent
        ref={forwardedRef}
        {...props}
        className={cx(props.className, styles.tab({ orientation, button: true }))}
      >
        {children}
      </ButtonComponent>
    );
  },
);

export const Content = ({ children, ...props }: ContentProps) => {
  return <PrimitiveTabs.Content {...props}>{children}</PrimitiveTabs.Content>;
};
