import * as React from 'react';
import { SVGProps } from 'react';
const SvgLetterSpacing = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.75 13.25 8 4.75l3.25 8.5M6 10.25h4M18.25 11a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0ZM13.75 4.75v8.5M17.75 19.25l1.5-1.25-1.5-1.25M6.25 19.25 4.75 18l1.5-1.25M19 18H5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLetterSpacing;
