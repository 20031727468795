import * as React from 'react';
import { SVGProps } from 'react';
const SvgLineHeight2 = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.75 19.25h14.5m-10-6h5.5m-5.5 0-1.5 3m1.5-3L12 7.75l2.75 5.5m0 0 1.5 3M4.75 4.75h14.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLineHeight2;
