import React from 'react';

interface Props {
  className?: string;
}

const DistributionSidepop = ({ className }: Props) => (
  <svg className={className} width="31" height="42" viewBox="0 0 31 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_ddd_662_19733)">
      <rect x="7" y="12" width="16" height="20" rx="3" fill="white" />
      <rect x="9" y="27" width="12" height="3" rx="1.5" fill="#0072DC" />
    </g>
    <path d="M15.1421 1L15.1421 6" stroke="#AA00FF" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M22.748 2.50195L20.8466 7.1263" stroke="#AA00FF" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M7.53613 2.50195L9.43755 7.1263" stroke="#AA00FF" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M29.2842 6.8584L25.7486 10.3939" stroke="#AA00FF" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M1 6.8584L4.53553 10.3939" stroke="#AA00FF" strokeWidth="1.5" strokeLinecap="round" />
    <defs>
      <filter
        id="filter0_ddd_662_19733"
        x="4.5"
        y="11"
        width="21"
        height="26.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_662_19733" />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_662_19733" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_662_19733" result="effect2_dropShadow_662_19733" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1.5" operator="erode" in="SourceAlpha" result="effect3_dropShadow_662_19733" />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_662_19733" result="effect3_dropShadow_662_19733" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_662_19733" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default DistributionSidepop;
