import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React, { ReactNode } from 'react';
import * as style from './Tooltip.css';

export const TooltipProvider = TooltipPrimitive.Provider;

type Variant = 'light' | 'dark';

export interface TooltipProps extends TooltipPrimitive.TooltipProps {
  /** must be true if your trigger is a `<button>` */
  asChild?: boolean;
  side?: TooltipPrimitive.TooltipContentProps['side'];
  sideOffset?: number;
  align?: TooltipPrimitive.TooltipContentProps['align'];
  children: ReactNode;
  content: ReactNode;
  variant?: Variant;
  height?: 'limited';
  padding?: 'small' | 'medium';
}

export const Tooltip = ({
  asChild,
  side,
  sideOffset,
  children,
  content,
  align,
  variant = 'light',
  height,
  padding = 'medium',
  ...rootProps
}: TooltipProps) => {
  return (
    <TooltipPrimitive.Root {...rootProps}>
      {asChild ? (
        // I thought that having just one Trigger case with className={asChild ? undefined : style.trigger} would be
        // good, but it ends up overwriting the entire className of the trigger child, so if you use our Button as the
        // trigger then all its styles would be gone. So instead the className prop needs to be entirely absent. We
        // could also achieve this via prop spreading and a tertiary, but this way felt a little more readable.
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      ) : (
        <TooltipPrimitive.Trigger className={style.trigger} type="button">
          {children}
        </TooltipPrimitive.Trigger>
      )}
      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
          sideOffset={sideOffset}
          side={side}
          className={style.content({ variant, height, padding })}
          align={align}
        >
          {content}
          <TooltipPrimitive.Arrow width={12} height={6} fill="currentColor" className={style.arrow({ variant })} />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
};
