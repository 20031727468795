import React from 'react';

import { Box } from '../Box';

import * as style from './Divider.css';

type BoxProps = React.ComponentProps<typeof Box>;

type Props = {
  style?: BoxProps['style'];
};

export const Divider = (props: Props) => <Box border="default" className={style.divider} {...props} />;
