import * as React from 'react';
import { Flex } from '@mutiny-pkg/dumpster-ui';
import { Box } from '../Box';
import { gridBaseStyles, headerBaseStyles, sidebarBaseStyles, mainPaneBaseStyles } from './TwoPaneLayout.css';

export interface TwoPaneLayoutProps {
  renderHeader: () => React.ReactNode;
  renderSidebar: () => React.ReactNode;
  children?: React.ReactNode;
  headerPadding?: 'default' | 'minimal';
  accentPane?: 'left' | 'right';
}

export const TwoPaneLayout: React.FC<TwoPaneLayoutProps> = ({
  renderHeader,
  renderSidebar,
  headerPadding = 'default',
  children,
  accentPane,
}) => {
  return (
    <Box className={gridBaseStyles}>
      <Box className={headerBaseStyles({ padded: headerPadding })}>{renderHeader()}</Box>
      <Flex.Container>
        <Flex.Container
          flex={1}
          className={mainPaneBaseStyles({ color: !accentPane || accentPane === 'left' ? 'default' : 'muted' })}
        >
          {children}
        </Flex.Container>
        <Box
          display="flex"
          flexDirection="column"
          className={sidebarBaseStyles({ color: !accentPane || accentPane === 'right' ? 'default' : 'muted' })}
        >
          {renderSidebar()}
        </Box>
      </Flex.Container>
    </Box>
  );
};
