import React from 'react';
import { Spinner } from './Spinner';
import { Flex } from '../Flex';
import { Typography } from '../Typography';
import * as styles from './FullPageLoader.css';

type FullPageLoaderProps = {
  fillViewport?: boolean;
};

export const FullPageLoader = ({ fillViewport }: FullPageLoaderProps) => {
  return (
    <Flex.Container className={styles.loader({ fillViewport })} gap="4u" justifyContent="center" alignItems="center">
      <Spinner />
      <Typography as="span" whiteSpace="nowrap">
        Loading&#8230;
      </Typography>
    </Flex.Container>
  );
};
